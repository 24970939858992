:root {
  --color-dark-grey: #1D1D1B;
  --color-brand: #00FFE0;
  --color-brand-light: #73F9DC;
  --color-brand-middle: #068F78;
  --color-brand-dark: #1F3731;
  --color-secondary: #000;
  --color-accent: #fff;
  --color-status-error: #FF4040;
  --color-status-warning: #FFAA15;
  --color-status-ok: #00C781;
  --color-unsuited: #333;
  --height-battle-row: 72px;
  --height-tournament-row: 72px;
  --transition-move-ms: 150ms;
  --transition-glow-ms: 200ms;
  --spacing: 24px;
}

*,
::after,
::before {
  box-sizing: inherit;
  /*border:  1px dashed tomato;*/
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: #00FFE0;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 0px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (min-width: 480px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) {
  html {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@keyframes float {
    0% { top: 0; }
    30% { top: -30px; }
    90% { top: 5px; }
    100% { top: 0; }
}
@keyframes shadow-aperture {
  0% { 
    opacity: .35;
    transform: translate(-50%, -50%) scale(1, 0.2)
  }
  30% { 
    opacity: .15;
    transform: translate(-50%, -50%) scale(.8, 0.15);
  }
  90% { 
    opacity: .4;
    transform: translate(-50%, -50%) scale(1.1, 0.25);}
  100% { 
    opacity: .35;
    transform: translate(-50%, -50%) scale(1, 0.2);
  }
}

@keyframes inflate {
  0% { transform: scale(1); }
  30% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(1080deg); }
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* fix for CRA bug */
iframe {
  pointer-events: none;
}
